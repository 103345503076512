<template>
  <div class="v-tree">
    <ul v-if="value" style="padding: 0">
      <tree-item v-if="isSingle" class="v-item" @change="onChange($event, index)" :key="0" :el="value" :index="0" :canEdit="canEdit" />
      <tree-item
        v-else
        class="v-item"
        @remove="onRemove($event)"
        @change="onChange($event, index)"
        @rename="onRename($event, index)"
        @copy="onCopy($event, index)"
        @code="onCode($event, index)"
        v-for="(el, index) in value"
        :key="index"
        :el="el"
        :index="index"
        :canEdit="canEdit"
      />

      <tree-add-item v-if="!isSingle && canEdit" @change="onChangeAddItem($event)"></tree-add-item>
    </ul>
    <s-editor-edit-field ref="editField" />
    <portal to="v-main"> </portal>
  </div>
</template>

<script>
import TreeItem from "@/views/tree/treeItem.vue";
import treeAddItem from "@/views/tree/treeAddItem.vue";
import _ from "lodash";
export default {
  components: {
    TreeItem,
    treeAddItem,
  },
  props: {
    value: [Object, Number, String],
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$root._drag_src_el = null;
  },
  computed: {
    isSingle() {
      if (typeof this.value === "string" || typeof this.value === "number") return true;
      return false;
    },
  },
  methods: {
    onRemove: function (index) {
      if (this.isSingle) return;
      this.$emit("remove", { i: index });
      this.$forceUpdate();
    },
    onChange: function (e, index) {
      if (this.isSingle) {
        this.$emit("change", { v: e, i: -1 });

        return;
      }
      this.$emit("change", { v: e, i: index });
      this.$forceUpdate();
    },
    onRename: function (e, index) {
      if (this.isSingle) return;
      this.$emit("rename", { v: e, i: index });
      this.$forceUpdate();
    },

    async changeCode(e) {
      let val = _.get(this.value, e);
      console.log("changeCode in code", e, val);
      let model = {};
      model.editField = "field";
      model.name = "field";
      model.type = "text";
      model.title = e;
      const editField = { data: { field: JSON.stringify(val) }, model: [model] };
      //console.log("refs", this.$refs);
      //return false;
      return this.$refs.editField.show(editField);
    },
    async onCode(e, index) {
      console.log("onCode in block", e);
      let v = await this.changeCode(e);
      let res;
      if (v) {
        try {
          console.log("try convert json");
          res = JSON.parse(v.value);
        } catch (error) {
          try {
            console.log("error not json");
            console.log("try eval");
            res = eval("(" + v.value + ")");
          } catch (error) {
            console.log("error not eval", error);
          }
        }

        console.log("do change code", res);
        // _.set(this.value, e, JSON.parse(res.value));
        if (res !== undefined) _.set(this.value, e, res);
        else {
          this.$root.$emit("show-info", {
            text: "Неверный формат",
            type: "error",
          });
        }
        return;
        let t = this.el;
        t[res] = t[e];
        this.$emit("change", t);
        this.$forceUpdate();

        //this.data[e.i] = e.v;
      }
    },
    onCopy: function (e, index) {
      this.$emit("copy", { v: e, i: index });
      this.$forceUpdate();
    },
    onChangeAddItem: function (e) {
      this.$emit("create", e);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.v-tree {
  position: relative;

  ul {
    padding: 0;
  }

  li {
    padding-left: 20px;
    list-style: none;
  }

  ul:hover,
  li:hover {
    cursor: pointer;
  }

  .folder {
    font-weight: bold;
  }

  i {
    color: var(--primary);
  }
}
.v-item {
}
</style>
